<template>
  <main id="article-id">
    <WidthWrapper>
      <div class="mb-40">
        <MainMedia v-if="!!apiData.data.attributes.media" :media="apiData.data.attributes.media" :disabled="apiData.data.attributes.disableMainMedia" :page-type="apiData.data.type" :description="apiData.data.attributes.mediaDescription" :alt="apiData.data.attributes.mediaAlt" />
        <div class="mb-10 uppercase font-normal text-20 text-center px-35">{{ apiData.data.attributes.categoryName }}</div>
        <PageTitle :title="title" page-type="page" />
        <div class="mb-40 md:mb-35">
          <div class="font-normal text-11 text-center px-15 text-grey-400">
            <div>
              <span v-if="apiData.data.attributes.byline">{{ apiData.data.attributes.byline }}</span>
            </div>
            <div v-if="apiData.data.attributes.credits" class="mt-10">{{ apiData.data.attributes.credits }}</div>
          </div>
        </div>
        <SomeShare class="mb-40" />
      </div>
      <AdWrapper class="mb-40" :ad="{ slotId: 'mob_responsive_2' }" />
      <AdWrapper class=" mb-40" :ad="{ slotId: 'dsk_rec_ad_2' }" />
      <div v-if="apiData.data.attributes.teaser" class="bg-white px-25 lg:px-[150px] mb-40 md:mb-50 text-center font-normal text-20 leading-28 md:text-22 md:leading-32 md:font-medium">{{ apiData.data.attributes.teaser }}</div>
      <div class="px-20 relative">
        <div class="absolute inset-0 z-10 pointer-events-none">
          <WidthWrapper class="h-full" />
        </div>
        <ContentComponents :content-components="apiData.data.attributes.contentComponents" class="content-grid z-20 relative" />
      </div>
      <SomeShare class="mb-40" />
    </WidthWrapper>
  </main>
</template>

<script setup lang="ts">
const apiData = inject('apiData') as Aller.Article;

/**
 *
 * Decides if title has prefix in it or not
 *
 * @returns String
 */
const title = apiData.data.attributes.titlePrefix
  ? `${apiData.data.attributes.titlePrefix} ${apiData.data.attributes.title}`
  : apiData.data.attributes.title
</script>
